import React from "react"
import SvgIcon from "./"

const SeoContentIcon = props => (
  <SvgIcon width={121} height={87} {...props}>
    <g fill="none" fillRule="evenodd">
      <g stroke="#19243B" strokeLinecap="round" strokeLinejoin="round">
        <path d="M70.8019804 41.0249612c.0034852-4.92732294-3.4999183-9.12549287-8.2613363-9.8996541-4.76141801-.77416123-9.36663614 2.10562404-10.8595096 6.79078706-1.49287344 4.68516304.57000416 9.78413834 4.8644469 12.02383244v8.3868061h8.9102477v-8.3776999c3.2815299-1.7114252 5.3468322-5.1589361 5.3461513-8.9240716zM56.54455465 54.9108911h8.91089105" />
        <path d="M55.95049525 39.61386139l3.56435643 3.56435641 3.56435642-3.56435641 3.5643565 3.56435641" />
      </g>
      <path
        d="M111.19849316 6.99041469l6.02069194 6.95337147c.3259037.50776664.5134878 1.11216051.5134878 1.75918404 0 1.8046557-1.4618297 3.2673267-3.26756355 3.2673267-.92560441 0-1.76215374-.3855616-2.35711748-1.0051127l-6.9927187-8.44540959c-.28421835-.377983-.45190717-.84690928-.45190717-1.35657057 0-1.24952274 1.01276471-2.26221404 2.26143064-2.26221404.686861 0 1.30077263.30503891 1.71573141.78817508l5.91742596 6.97800194c.16958359.21409564.27095479.48503082.27095479.77964915 0 .69344249-.56275229 1.25615403-1.25719245 1.25615403-.40643224 0-.76833693-.19325447-.99760639-.49260943l-5.00697986-6.04204404"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g stroke="#19243B" strokeLinecap="round" strokeLinejoin="round">
        <path d="M43.34498072 20.12212277c-13.1310189 9.26047609-16.99400678 26.88926648-8.85327545 40.4020263 8.14073129 13.51275972 25.52313459 18.32478033 39.83735123 11.0282883 14.31421664-7.29649205 20.70361403-24.22588925 14.64335284-38.79914248" />
        <path
          d="M71.1551301 1.148515v33.5850794h33.5082357C103.0329586 16.9057334 88.9422004 2.78266118 71.1551301 1.148515z"
          fill="#D7E6F2"
        />
      </g>
      <path
        d="M96.1470801 52.2765783h16.257617c2.7750549 0 5.0455544 2.2629646 5.0455544 5.0288102v23.46778124c0 2.76584564-2.2704995 5.02881026-5.0455544 5.02881026H77.92674235c-2.77505489 0-5.04555435-2.26296462-5.04555435-5.02881026V59.8197937h4.20462863c1.8500366 0 3.3637029-1.7014142 3.3637029-3.7716077v-3.7716077h4.35431337"
        stroke="#19243B"
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.2355136 52.2765783h3.1553319"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.37510133 52.2765783v3.7469566c0 2.0566629-1.49878267 3.7469567-3.33062815 3.7469567H72.881188l7.49391333-7.4939133z"
        fill="#D7E6F2"
      />
      <path
        d="M80.37510133 52.2765783v3.7469566c0 2.0566629-1.49878267 3.7469567-3.33062815 3.7469567H72.881188l7.49391333-7.4939133z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.6875503 74.1666936H80.5723092M111.53400376 70.22252869H80.5723092M106.40658937 78.1108585H80.5723092M104.82892341 65.8839473H80.5723092M107.58983885 61.5453659H80.5723092"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#D7E6F2"
        d="M112.5387455 46.03843156l3.41411242 3.73376533-15.63537583 13.86043555-3.41411238-3.73376533z"
      />
      <path
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M112.5387455 46.03843156l3.41411242 3.73376533-15.63537583 13.86043555-3.41411238-3.73376533z"
      />
      <path
        fill="#D7E6F2"
        d="M95.47937646 64.54892093l4.75746885-.86949246-3.3734946-3.65739554z"
      />
      <path
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M95.47937646 64.54892093l4.75746885-.86949246-3.3734946-3.65739554zM114.82929668 44.70910654l2.34923828 2.5672026-1.93551249 1.71436546-2.34923837-2.56720264z"
      />
      <path
        fill="#D7E6F2"
        d="M116.5201338 42.2340276l3.27092117 3.75740146-2.47824915 2.30614987-3.27092127-3.75740149z"
      />
      <path
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M116.5201338 42.2340276l3.27092117 3.75740146-2.47824915 2.30614987-3.27092127-3.75740149z"
      />
      <path
        stroke="#19243B"
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M45.5419178 26.8757955l.16744995-9.66812068-9.65628011.16765523-.06089088 3.51568026 6.1449055-.10668976-.10655908 6.15244057z"
      />
      <path
        stroke="#111"
        fill="#D7E6F2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M40.50495 71.2475243h-9.20792079V85.50495H40.50495z"
      />
      <path
        stroke="#111"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M27.7326728 59.66336585h-9.20792079v25.8415842h9.20792079z"
      />
      <path
        d="M14.4309563 75.4705603c1.1019586-1.1019586 1.1019586-2.8886679 0-3.9906265-1.1019586-1.1019586-2.8886679-1.1019586-3.9906265 0-1.1019586 1.1019586-1.1019586 2.8886679 0 3.9906265 1.1019586 1.1019586 2.8886679 1.1019586 3.9906265 0m-9.7236222 3.5230269l1.4445461-2.0091876c-.2457357-.446637-.4412382-.9190676-.5824345-1.4064313l-2.4451386-.4289887c-.1968602-.033939-.3421294-.206349-.3421294-.4072677v-2.2711965c.0013577-.2022763.1479845-.3760439.34756-.4086253l2.4329198-.3977649c.1425538-.4941515.3380563-.9692972.5865074-1.4200069l-1.4187506-2.02004799c-.1154008-.16426466-.0977513-.38826192.0448026-.53080563l1.6074649-1.60599249c.1425538-.14390128.3692824-.16154955.5335588-.0434419l1.9903239 1.43086736c.4507418-.24979088.9259215-.44799452 1.4160353-.59189579l.4032239-2.41917044c.0325837-.19956119.2050061-.34482002.4086545-.34482002h2.270001c.2009331 0 .3733554.14390127.4072968.34074735l.4290194 2.41374019c.4955445.14254371.9747971.34074735 1.4296119.5918958l1.9876085-1.42136445c.1642764-.11675009.3896473-.09910182.5335589.0434419l1.6061072 1.60735005c.1425539.14254371.1615611.36518341.0475179.52944807l-1.4024587 2.00647249c.2498087.4534247.4466688.9326431.591938 1.4308673l2.4111973.40048c.1995755.0325814.3448447.2049914.3448447.4072677l.0013576 2.2725541c0 .2009187-.1439115.3733287-.3421293.4072677l-2.4193432.4262736c-.1439116.4955091-.3421294.9720124-.5905804 1.422722l1.4282542 1.9983271c.1167584.1629071.0977512.3882619-.0448027.5308056l-1.6074648 1.6073501c-.1411962.1411861-.3638518.1615495-.5281282.046157l-2.0269806-1.4159342c-.4520995.2470757-.9286368.4425643-1.4201083.5837504l-.4290194 2.4381763c-.0339414.1982036-.205006.3421049-.4072968.3421049l-2.2713587-.0013576c-.2036484 0-.3760707-.1466164-.4086545-.3461776l-.3964356-2.4422489c-.4901138-.1425437-.9612205-.3380322-1.4078894-.5837504L6.8877298 81.180615c-.1642764.1153925-.3882896.0950291-.5294859-.046157l-1.6074648-1.6073501c-.1439116-.1425437-.1615611-.3692561-.043445-.5335207"
        fill="#D7E6F2"
      />
      <path
        d="M14.4309563 75.4705603c1.1019586-1.1019586 1.1019586-2.8886679 0-3.9906265-1.1019586-1.1019586-2.8886679-1.1019586-3.9906265 0-1.1019586 1.1019586-1.1019586 2.8886679 0 3.9906265 1.1019586 1.1019586 2.8886679 1.1019586 3.9906265 0zm-9.7236222 3.5230269l1.4445461-2.0091876c-.2457357-.446637-.4412382-.9190676-.5824345-1.4064313l-2.4451386-.4289887c-.1968602-.033939-.3421294-.206349-.3421294-.4072677v-2.2711965c.0013577-.2022763.1479845-.3760439.34756-.4086253l2.4329198-.3977649c.1425538-.4941515.3380563-.9692972.5865074-1.4200069l-1.4187506-2.02004799c-.1154008-.16426466-.0977513-.38826192.0448026-.53080563l1.6074649-1.60599249c.1425538-.14390128.3692824-.16154955.5335588-.0434419l1.9903239 1.43086736c.4507418-.24979088.9259215-.44799452 1.4160353-.59189579l.4032239-2.41917044c.0325837-.19956119.2050061-.34482002.4086545-.34482002h2.270001c.2009331 0 .3733554.14390127.4072968.34074735l.4290194 2.41374019c.4955445.14254371.9747971.34074735 1.4296119.5918958l1.9876085-1.42136445c.1642764-.11675009.3896473-.09910182.5335589.0434419l1.6061072 1.60735005c.1425539.14254371.1615611.36518341.0475179.52944807l-1.4024587 2.00647249c.2498087.4534247.4466688.9326431.591938 1.4308673l2.4111973.40048c.1995755.0325814.3448447.2049914.3448447.4072677l.0013576 2.2725541c0 .2009187-.1439115.3733287-.3421293.4072677l-2.4193432.4262736c-.1439116.4955091-.3421294.9720124-.5905804 1.422722l1.4282542 1.9983271c.1167584.1629071.0977512.3882619-.0448027.5308056l-1.6074648 1.6073501c-.1411962.1411861-.3638518.1615495-.5281282.046157l-2.0269806-1.4159342c-.4520995.2470757-.9286368.4425643-1.4201083.5837504l-.4290194 2.4381763c-.0339414.1982036-.205006.3421049-.4072968.3421049l-2.2713587-.0013576c-.2036484 0-.3760707-.1466164-.4086545-.3461776l-.3964356-2.4422489c-.4901138-.1425437-.9612205-.3380322-1.4078894-.5837504L6.8877298 81.180615c-.1642764.1153925-.3882896.0950291-.5294859-.046157l-1.6074648-1.6073501c-.1439116-.1425437-.1615611-.3692561-.043445-.5335207z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9211373 38.0745922H11.7272212v4.5797251h5.7771171c-.9228363 3.0530214-3.2037812 4.0705665-5.8235167 4.0705665-2.78956028.0039338-5.25059447-1.9125676-6.05248704-4.7133031-.80189256-2.8007354.24565064-5.8210728 2.57625095-7.4279962 2.33060032-1.6069234 5.39119159-1.4190999 7.52699389.4619194l3.1746894-3.169213c-3.5160761-3.3908139-8.75314005-3.9202351-12.82257291-1.2962497-4.06943287 2.6239855-5.98047248 7.7625305-4.67904248 12.5813563 1.30143001 4.8188259 5.49847772 8.1447199 10.27616819 8.1432119 5.8886971 0 11.2110246-4.070567 10.2403157-13.2300172z"
        stroke="#1A253C"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
)

export default SeoContentIcon
