import React, { Component } from "react"
import SeoBase from "../components/SeoBase"
import Hero from "../components/Hero"
import WhatWeDo from "../components/WhatWeDo"
import Clients from "../components/Clients"
import Testimonials from "../components/Testimonials"
import ContactSection from "../components/ContactSection"

import BaseLayout from "../layouts/BaseLayout"

const testimonials = [
  // {
  //   author: "Toyota Bielany",
  //   content: `Yupi-There to profesjonalny i godzien zaufania zespół.
  //   Wykonywane dla nas projekty graficzne, projekty mailingów jak i
  //   działania SEM - wszystko na wysokim poziomie, Jeśli miałabym wskazać
  //   największą wartość współpracy, to byłoby to super podejście do klienta.`,
  // },
  {
    author: "Dariusz Zwadowski & Konsultanci Ślubni",
    content: `Z wielką przyjemnością mogę polecić współpracę z Yupi-There.
    Jestem bardzo zadowolony z całokształtu obsługi – począwszy od przygotowania
    oferty, przez prowadzenie, raportowanie i inicjowanie pro-aktywności.
    Przy tym wszystkim widzę, że to zgrany i uprzejmy zespół, co jest bardzo
    ważne w dzisiejszych czasach, kiedy opieramy swoje biznesy przede wszystkim
    na relacjach.`,
  },
  {
    author: "Browar Waszczukowe",
    content: `Promocja branży alkoholowej w digital marketingu nie
    jest najłatwiejsza, szczególnie kiedy musimy mierzyć
    się z różnego rodzaju przepisami i obostrzeniami.
    Praca przy tworzeniu nowofalowej marki wymaga pionierskiego
    podejścia i w tym Yupi-There radzi sobie najlepiej
    wykorzystując ogrom swojego doświadczenia!`,
  },
  {
    author: "PSTQ",
    content: `Bardzo profesjonalnie świadczone usługi - zarówno
    SEO jak i SEM. Mimo, że branża medycyny estetycznej
    nie jest najłatwiejsza do reklamowania, Panowie zawsze
    znajdywali wyjście z sytuacji. Kreatywni fachowcy
    od online marketingu z dużą wiedzą i doświadczeniem.`,
  },
  {
    author: "Toyota Romanowski",
    content: `Z firmą Yupi-there współpracujemy od kilku lat
    głównie w obrębie działań SEM. Współpracę oceniamy bardzo dobrze.
    Kontakt przebiega bardzo sprawnie, wszelkie działania
    są podejmowane natychmiastowo. Nasze kampanie są w dobrych rękach.`,
  },
]

const clients = {
  content: `Zaufali nam klienci niemal ze wszystkich branż. Kilka lat na rynku
  oraz doświadczenie z pracy z wieloma firmami utwierdziło nas w
  przekonaniu jak wiele do każdego biznesu może wnieść reklama
  internetowa. Jesteśmy profesjonalistami, którzy potrafią słuchać
  Klientów i pracować na ich sukces.`,
}

const webpageSchema = {
  name: "Strona główna",
}

const organizationSchema = {
  name: "Yupi-There",
  url: "www.yupi-there.com",
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[+48505950321]",
    email: "biuro@yupi-there.pl",
  },
}

class Homepage extends Component {
  render() {
    return (
      <BaseLayout>
        <SeoBase
          title="Yupi-There - Skuteczny Marketing Internetowy"
          description="SEM, SEO, Analityka internetowa – Pracujemy na sukces Twojego biznesu!"
          webpageSchema={webpageSchema}
          organizationSchema={organizationSchema}
        >
          <Hero />
          <WhatWeDo />
          <Clients {...clients} />
          <Testimonials clients={testimonials} />
          <ContactSection />
        </SeoBase>
      </BaseLayout>
    )
  }
}

export default Homepage
