import React from "react"
import SvgIcon from "./"

const SocialMediaCampaignsIcon = props => (
  <SvgIcon width={121} height={92} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M24.19413784 32.0749185v-4.94777895h-1.78600488v-1.92825193h1.78600488v-1.42202685c0-1.65025314 1.08114944-2.54884777 2.66024723-2.54884777.75640373 0 1.40649541.05250149 1.59594738.07596778v1.72461114l-1.09518811.00046346c-.85880219 0-1.02508731.38044782-1.02508731.93873065v1.23110159h2.04816229l-.26667919 1.92825193h-1.78148575v4.94777895h-2.13591654z"
        fillRule="nonzero"
        stroke="#19243B"
      />
      <path
        d="M107.546526 28.6779189v21.6891743M107.546526 58.9588303v7.4192646c0 3.1639296-2.684284 5.7291618-5.99505 5.7291618H17.6177796c-3.3107663 0-5.9950499-2.5652322-5.9950499-5.7291618V17.6787873c0-3.1639296 2.6842836-5.7291618 5.9950499-5.7291618h83.9336964c3.310766 0 5.99505 2.5652322 5.99505 5.7291618v6.2075468"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.94116 80.8012597c0 1.5812487-1.3413924 2.8645809-2.9975249 2.8645809-1.6546338 0-2.997525-1.2833322-2.997525-2.8645809s1.3428912-2.8645809 2.997525-2.8645809c1.6561325 0 2.9975249 1.2833322 2.9975249 2.8645809"
        fill="#D7E6F2"
      />
      <path
        d="M33.94116 80.8012597c0 1.5812487-1.3413924 2.8645809-2.9975249 2.8645809-1.6546338 0-2.997525-1.2833322-2.997525-2.8645809s1.3428912-2.8645809 2.997525-2.8645809c1.6561325 0 2.9975249 1.2833322 2.9975249 2.8645809zM30.9436351 72.1529467v5.7835889"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#D7E6F2"
        d="M53.5898776 76.4945055h11.9900998v-4.3871056H53.5898776z"
      />
      <path
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.5898776 86.4303044h11.9900998V72.1073999H53.5898776z"
      />
      <path
        d="M7.03426835 83.6658406c0 1.5812487-1.34289119 2.8645809-2.99752496 2.8645809-1.65463378 0-2.99752496-1.2833322-2.99752496-2.8645809s1.34289118-2.8645809 2.99752496-2.8645809c1.65463377 0 2.99752496 1.2833322 2.99752496 2.8645809"
        fill="#D7E6F2"
      />
      <path
        d="M7.03426835 83.6658406c0 1.5812487-1.34289119 2.8645809-2.99752496 2.8645809-1.65463378 0-2.99752496-1.2833322-2.99752496-2.8645809s1.34289118-2.8645809 2.99752496-2.8645809c1.65463377 0 2.99752496 1.2833322 2.99752496 2.8645809z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.974122 8.25416045c0 3.95455395-3.35423 7.16145225-7.493812 7.16145225-4.138084 0-7.4938127-3.2068983-7.4938127-7.16145225 0-3.95598622 3.3557287-7.16145225 7.4938127-7.16145225 4.139582 0 7.493812 3.20546603 7.493812 7.16145225"
        fill="#FFF"
      />
      <path
        d="M112.974122 8.25416045c0 3.95455395-3.35423 7.16145225-7.493812 7.16145225-4.138084 0-7.4938127-3.2068983-7.4938127-7.16145225 0-3.95598622 3.3557287-7.16145225 7.4938127-7.16145225 4.139582 0 7.493812 3.20546603 7.493812 7.16145225z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.9158799 11.6915143c1.2754468 2.2200502 3.7319181 3.7239552 6.5645801 3.7239552 4.138083 0 7.493812-3.2083306 7.493812-7.16145227 0-2.70702895-1.573701-5.05598529-3.896783-6.27343218.56953 1.01692622.899258 2.17708149.899258 3.40885128 0 3.95312164-3.355729 7.16145227-7.493812 7.16145227-1.287437 0-2.5029338-.3151039-3.5670551-.8593743"
        fill="#D7E6F2"
      />
      <path
        d="M112.974122 8.25416045c0 3.95455395-3.35423 7.16145225-7.493812 7.16145225-4.138084 0-7.4938127-3.2068983-7.4938127-7.16145225 0-3.95598622 3.3557287-7.16145225 7.4938127-7.16145225 4.139582 0 7.493812 3.20546603 7.493812 7.16145225z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.5688012 90.727319H38.6004543c-.8273169 0-1.4987625-.6416661-1.4987625-1.4322905v-1.4322904c0-.7906244.6714456-1.4322905 1.4987625-1.4322905h41.9683469c.8273169 0 1.4987625.6416661 1.4987625 1.4322905v1.4322904c0 .7906244-.6714456 1.4322905-1.4987625 1.4322905"
        fill="#D7E6F2"
      />
      <path
        d="M80.5688012 90.727319H38.6004543c-.8273169 0-1.4987625-.6416661-1.4987625-1.4322905v-1.4322904c0-.7906244.6714456-1.4322905 1.4987625-1.4322905h41.9683469c.8273169 0 1.4987625.6416661 1.4987625 1.4322905v1.4322904c0 .7906244-.6714456 1.4322905-1.4987625 1.4322905z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.45304265 21.54372402c0 1.06499624-.90430383 1.92934101-2.01853533 1.92934101-1.1142315 0-2.01853532-.86434477-2.01853532-1.92934101s.90430383-1.92934102 2.01853532-1.92934102c1.1142315 0 2.01853533.86434478 2.01853533 1.92934102"
        fill="#D7E6F2"
      />
      <path
        d="M83.45304265 21.54372402c0 1.06499624-.90430383 1.92934101-2.01853533 1.92934101-1.1142315 0-2.01853532-.86434477-2.01853532-1.92934101s.90430383-1.92934102 2.01853532-1.92934102c1.1142315 0 2.01853533.86434478 2.01853533 1.92934102z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.3160628 21.54372402c0 1.06499624-.90430387 1.92934101-2.01853537 1.92934101-1.1142315 0-2.01853533-.86434477-2.01853533-1.92934101s.90430383-1.92934102 2.01853533-1.92934102c1.1142315 0 2.01853537.86434478 2.01853537 1.92934102"
        fill="#D7E6F2"
      />
      <path
        d="M90.3160628 21.54372402c0 1.06499624-.90430387 1.92934101-2.01853537 1.92934101-1.1142315 0-2.01853533-.86434477-2.01853533-1.92934101s.90430383-1.92934102 2.01853533-1.92934102c1.1142315 0 2.01853537.86434478 2.01853537 1.92934102z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.1790829 21.54372402c0 1.06499624-.9043039 1.92934101-2.0185354 1.92934101-1.1142315 0-2.0185353-.86434477-2.0185353-1.92934101s.9043038-1.92934102 2.0185353-1.92934102c1.1142315 0 2.0185354.86434478 2.0185354 1.92934102"
        fill="#D7E6F2"
      />
      <path
        d="M97.1790829 21.54372402c0 1.06499624-.9043039 1.92934101-2.0185354 1.92934101-1.1142315 0-2.0185353-.86434477-2.0185353-1.92934101s.9043038-1.92934102 2.0185353-1.92934102c1.1142315 0 2.0185354.86434478 2.0185354 1.92934102z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.86445 67.8479083H22.9171684c-3.5637241 0-6.47949834-2.585317-6.47949834-5.7451488V21.88671781c0-3.15983185 2.91577424-5.74514881 6.47949834-5.74514881h73.4343151c3.5637242 0 6.4794984 2.58531696 6.4794984 5.74514881V49.0781764"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#FFF"
        d="M27.3377608 60.9022806h7.2667272V36.5925838h-7.2667272z"
      />
      <path
        d="M92.2662285 27.7176151c1.3758142 0 2.4906121 1.0362877 2.4906121 2.3152092 0 1.2789216-1.1147979 2.3152092-2.4906121 2.3152092H37.0951001c-1.3758141 0-2.4906121-1.0362876-2.4906121-2.3152092 0-1.2789215 1.114798-2.3152092 2.4906121-2.3152092h55.1711284z"
        fill="#D7E6F2"
      />
      <path
        d="M37.0951001 27.7176151h55.1711284c1.3758142 0 2.4906121 1.0362877 2.4906121 2.3152092 0 1.2789216-1.1147979 2.3152092-2.4906121 2.3152092H37.0951001c-1.3758141 0-2.4906121-1.0362876-2.4906121-2.3152092 0-1.2789215 1.114798-2.3152092 2.4906121-2.3152092zM74.1677803 40.0653976H38.6415586M64.4788108 42.766475H38.6415586M59.634326 36.5925838H38.6415586M67.7084673 30.0328243H38.6415586M76.5900227 51.6414437H38.6415586M72.9566591 54.3425211H38.6415586M55.5972553 48.1686299H38.6415586M55.5972553 59.744676H38.6415586M27.3377608 60.9022806h7.2667272V36.5925838h-7.2667272z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3686595 33.690936c0 2.7686174-2.34856082 5.0130166-5.24566869 5.0130166-2.89860663 0-5.24566867-2.2443992-5.24566867-5.0130166s2.34706204-5.0130166 5.24566867-5.0130166c2.89710787 0 5.24566869 2.2443992 5.24566869 5.0130166"
        fill="#FFF"
      />
      <path
        d="M18.3686595 33.690936c0 2.7686174-2.34856082 5.0130166-5.24566869 5.0130166-2.89860663 0-5.24566867-2.2443992-5.24566867-5.0130166s2.34706204-5.0130166 5.24566867-5.0130166c2.89710787 0 5.24566869 2.2443992 5.24566869 5.0130166z"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.4688832 45.8534202H80.2497869c-.8815005 0-1.6027281-.7035831-1.6027281-1.5635179v-6.2540717c0-.8599348.7212276-1.5635179 1.6027281-1.5635179h11.2190963c.8815004 0 1.6027281.7035831 1.6027281 1.5635179v6.2540717c0 .8599348-.7212277 1.5635179-1.6027281 1.5635179"
        fill="#D7E6F2"
      />
      <path
        d="M91.4688832 45.8534202H80.2497869c-.8815005 0-1.6027281-.7035831-1.6027281-1.5635179v-6.2540717c0-.8599348.7212276-1.5635179 1.6027281-1.5635179h11.2190963c.8815004 0 1.6027281.7035831 1.6027281 1.5635179v6.2540717c0 .8599348-.7212277 1.5635179-1.6027281 1.5635179zM80.258312 61.3908795c-.8861892 0-1.6112532-.6925896-1.6112532-1.539088v-9.2345277c0-.8464983.725064-1.5390879 1.6112532-1.5390879h11.2787724M97.3682864 50.2508143v11.6097696c0 1.0315648-.8654731 1.8755725-1.9232736 1.8755725H84.4782609"
        stroke="#19243B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.5429962 42.9149604l.0331921.0237681c.0246917.0315609.0327873.0631218.0327873.1024755 0 .0946827-.0825754.1737797-.1813421.1737797H82.8764593c-.1323635 0-.2396307-.1028651-.2396307-.228719 0-.0475362.0161913-.0868899.0412878-.1266332l.033192-.0393537 1.9656193-2.279008c.0659793-.0790971.1651508-.1266332.2813231-.1266332.1153628 0 .2145343.0475361.2805136.1266332l.7350833.8517544c.0085005.0155856.0250965.0315609.0416925.0471465.0493834.0471465.1072671.0709146.1813422.0709146.0744798 0 .140864-.0315609.1902473-.0787074.0080956-.0077928.016596-.0237681.0246917-.0315609l.1983429-.2287191c.0497882-.0553289.1323636-.0946826.214939-.0946826.0902663 0 .1647461.0393537.2145343.0946826l1.5033586 1.7428625zm-.3902094-3.5110516c.3412309 0 .6221493.2637867.6221493.583292 0 .3195053-.2809184.583292-.6221493.583292-.340826 0-.6221492-.2637867-.6221492-.583292 0-.3195053.2813232-.583292.6221492-.583292z"
        stroke="#19243B"
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g stroke="#19243B" strokeLinecap="round" strokeLinejoin="round">
        <path d="M81.7161116 59.83487814h4.50127882M81.7161116 58.30142788h4.50127882M81.7161116 56.76797762h4.50127882M87.73246878 58.34087227l3.0003069 1.49400587-.62791723-3.4675081 2.65984655-2.45574696-3.67581121-.50588057-1.64388188-3.15492851-1.64388189 3.15492851-3.67581122.50588057 1.64426879 1.51800818" />
      </g>
      <g>
        <path
          d="M95.4265041 71.6514654c-4.3238415 0-8.1243311-2.1195588-10.33443205-5.3284526 1.95372924 1.2328908 4.28759585 1.9506697 6.79827055 1.9506697 6.8327481 0 12.3765654-5.2946748 12.3765654-11.8222404 0-2.3982259-.7523184-4.62756266-2.0430174-6.49378774 3.3593535 2.11111435 5.5791789 5.74223104 5.5791789 9.87157074 0 6.5275655-5.5438173 11.8222403-12.3765654 11.8222403z"
          fill="#D7E6F2"
        />
        <path
          fill="#FFF"
          d="M119.1585682 80.9589419l-2.8250975 2.7120675-10.0649281-9.5997922 2.8392319-2.7129114z"
        />
        <path
          d="M100.3139954 49.2045975c4.0941456 1.90440727 6.9209727 5.9490617 6.9209727 10.6304839 0 6.5117084-5.4687459 11.7919955-12.2109159 11.7919955-6.74391433 0-12.21091578-5.2802871-12.21091578-11.7919955 0-4.1162487 2.18313731-7.73976043 5.49403991-9.84968534m4.27469277-1.65019847c.6934835-.11168933 1.4058556-.16922626 2.1335185-.16922626 1.1036372 0 2.1748937.13368874 3.1948811.38498974m12.6549491 11.37454283c0-8.25004219-7.0000049-14.9515629-15.6512885-14.9515629-8.65216898 0-15.6530593 6.70152071-15.6530593 14.9515629 0 8.2500422 7.00089032 14.9507172 15.6530593 14.9507172 3.5991677 0 6.9150061-1.1544139 9.5473126-3.112266l3.0599566 2.9228237 2.8456888-2.7190041-3.0590712-2.9219781c2.0488251-2.5278704 3.2574017-5.681577 3.2574017-9.1202927z"
          stroke="#19243B"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="#D7E6F2"
          d="M119.1585682 80.3726226l-2.8357635 2.7120676-10.0542621-9.5997922 2.8357636-2.7129114 10.040145 9.600636z"
        />
        <path
          stroke="#19243B"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M119.1585682 80.3726226l-2.8357635 2.7120676-10.0542621-9.5997922 2.8357636-2.7129114 10.040145 9.600636z"
        />
        <path
          stroke="#19243B"
          fill="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M121.0000004 80.6836307l-4.3550733 4.1600171-1.7830341-1.7023724 4.3559136-4.1608197z"
        />
      </g>
    </g>
  </SvgIcon>
)

export default SocialMediaCampaignsIcon
